//base
import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";

//
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronUp,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Component } from "react";

class Footer extends React.Component {
  componentDidMount() {
    const js = `
      function ml_webform_success_w9a9j5() {
        var $ = ml_jQuery || jQuery;
        $('.ml-subscribe-form-w9a9j5 .ml-block-success').show();
        $('.ml-subscribe-form-w9a9j5 .ml-block-form').hide();
      };
    `;
    const script = document.createElement("script");
    const scriptText = document.createTextNode(js);
    script.appendChild(scriptText);
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?w9a9j5";
    document.body.appendChild(script2);
  }

  render() {
    return (
      <Container fluid>
        <Row className="footer">
          <Col lg="5" md="12" className="plr-5">
            <br />
            <img
              className="logo-footer"
              src="https://apiago.fr/img/LOGO_V2_BLANC.svg"
              alt="logo"
            />
            <br />
            <br />
            <p>
              <b>Apiago :</b>
              <br />
              <i>"quod flores ejus apes maxime appetunt"</i>
              <br />
              <i>"Les fleurs qui attirent les abeilles"</i>
            </p>
            <br />
            <h4 className="font-bold font-20px">Newsletter</h4>

            <div
              id="w9a9j5-w9a9j5"
              className="ml-subscribe-form ml-subscribe-form-w9a9j5"
            >
              <div className="ml-vertical-align-center">
                <div
                  className="subscribe-form ml-block-success"
                  style={{ display: "none" }}
                >
                  <div className="form-section">
                    <p>
                      Merci! Vous allez recevoir un mail pour confirmer votre
                      inscription.
                    </p>
                  </div>
                </div>
                <form
                  className="ml-block-form"
                  action="https://landing.mailerlite.com/webforms/submit/w9a9j5"
                  data-code="w9a9j5"
                  method="POST"
                  target="_blank"
                >
                  <p className="signup-title">
                    Inscrivez-vous à la newsletter pour ne rien rater !
                  </p>
                  <div className="subscribe-form">
                    <div className="form-section">
                      <div className="form-group ml-field-email ml-validate-required ml-validate-email newsletter-block">
                        <input
                          type="email"
                          name="fields[email]"
                          className="form-control signup-text newsletter-form"
                          placeholder="Votre adresse mail"
                          autoComplete="email"
                          spellCheck="false"
                          autoCapitalize="off"
                          autoCorrect="off"
                        />
                        <input type="hidden" name="ml-submit" value="1" />
                        <button
                          type="submit"
                          className="primary signup-button btn-round"
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                        <button
                          disabled
                          style={{ display: "none" }}
                          type="button"
                          className="loading btn-round"
                        >
                          <FontAwesomeIcon icon={faSpinner} />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <br />
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold">menu</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/" className="text-white">
                  Accueil
                </Link>
                <br></br>
                <Link to="/ruche" className="text-white">
                  La ruche Apiago
                </Link>
                <br></br>
                <Link to="/particulier" className="text-white">
                  Particulier
                </Link>
                <br></br>
                <Link to="/entreprise-et-collectivite" className="text-white">
                  Entreprise & Collectivite
                </Link>
                <br></br>
                <Link to="/location-et-parrainage" className="text-white">
                  Parrainer la biodiversité
                </Link>
                <br></br>
                <Link to="/cueilleur" className="text-white">
                  Cueilleur d'essaim
                </Link>
                <br></br>
                <Link to="/equipe" className="text-white">
                  L'équipe
                </Link>
                <br></br>
                <a href="https://apiago.fr/taberna" className="text-white">
                  Magasin
                </a>
                <br></br>
                <Link to="/contact" className="text-white">
                  Contact
                </Link>
                <br></br>
                <Link to="/faq" className="text-white">
                  FAQ
                </Link>
              </li>
            </ul>
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold">infos</h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/mentions" className="text-white" target="_blank">
                  Mentions légales{" "}
                </Link>
              </li>
              <li>
                <Link to="/cgv" className="text-white" target="_blank">
                  Condition générales de ventes{" "}
                </Link>
              </li>
            </ul>
          </Col>

          <Col md="12" lg="2" className="sm-text-center">
            <br />
            <br />
            <br />
            <h4 className="text-uppercase font-20px font-bold">social</h4>
            <ul className="list-unstyled">
              <li>
                <a
                  className="text-white"
                  href="https://www.youtube.com/channel/UC3xfvJNMZcd_oNUpkDXUqYg"
                  target="_blank"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  to=""
                  href="https://www.facebook.com/ApiagoHive"
                  target="_blank"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="https://www.linkedin.com/company/apiago"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  className="text-white"
                  href="https://www.instagram.com/apiago/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="row copyright justify-content-center">
          <p className="text-center mx-auto my-auto text-white">
            Copyright © 2022 Apiago - Tous droits réservés
          </p>
        </Row>
      </Container>
    );
  }
}

export default Footer;
