//base
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

//bibliothèques
import ReactFullpage from "@fullpage/react-fullpage";
import "fullpage.js/vendors/scrolloverflow";
import { Col, Row, Container, Button } from "react-bootstrap";
import FaqComponent from "react-faq-component";
//images

// Utilisation fullPage
const Faq = () => {
  const data1 = {
    title: "Questions les plus posées",
    rows: [
      {
        title:
          "Je n'y connais absolument rien aux abeilles. Est-il raisonnable d'installer cette ruche chez moi ?",
        content:
          "La ruche Apiago est conçue de telle sorte qu'il n'y a aucune connaissance à avoir sur les abeilles. Cette ruche est tout particulièrement adaptée pour les novices du monde des abeilles ",
      },
      {
        title:
          "Je crains pour moi ou mes proches les piqûres d'abeilles. Avoir une ruche augmente-t-il ce risque ?",
        content:
          "Une ruche traditionnelle est placée au sol. Cela peut alors effectivement présenter un risque pour les personnes non averties. La ruche Apiago est située à plusieurs mètres de hauteur et lorsqu'elles butinent, les abeilles ne présentent aucune agressivité. Vous aurez un plus grand nombre d'abeilles autour de chez vous mais aucune ne viendra vous déranger et encore moins vous piquer. De plus, les abeilles ne sont pas attirées par ce qui se trouve sur votre table (contrairement à certaines guêpes)",
      },
      {
        title: "Pourquoi choisir la ruche Apiago ?",
        content:
          "Notre ruche est unique, protégée par des brevets. Nous sommes les seuls à vous proposer un produit adapté au grand -public, ne nécessitant aucune connaissance préalable et pouvant être installé facilement chez vous. La ruche Apiago n’est produite qu’avec des composants et matériaux de qualité, conçus pour durer. La ruche Apiago est conçue de telle sorte que tous les composants puissent être remplacés simplement et à un prix raisonnable… comme vous, nous n’aimons pas avoir à racheter un produit neuf lorsqu’une petite réparation est suffisante. Il n’y a aucun abonnement obligatoire et vous bénéficierez toujours gratuitement des mises-à-jour de l’application dédiée. Nous assurons un service après-vente réactif et de qualité. Par votre achat vous contribuez à la préservation des abeilles et à la biodiversité. Votre achat profite également à la fondation Raspberry qui facilite dans le monde l’accès à l’informatique pour les plus défavorisés et en développe l’enseignement.",
      },
    ],
  };

  const data2 = {
    title: "La ruche Apiago",
    rows: [
      {
        title: "Puis-je récolter du miel de ma ruche ?",
        content:
          "Oui, avec le module 'récolte de miel', il est possible de récolter du miel produit par les abeilles d’une ruche Apiago. S’agissant d’une ruche de biodiversité, les quantités prélevées sont moins importantes que sur des ruches spécifiquement prévues pour la production de miel. Récolter du miel dans une ruche n’est pas obligatoire, vous pouvez laisser aux abeilles l’ensemble de leur production.",
      },
      {
        title: "Qu'est-ce que le module 'récolte de miel' ?",
        content:
          "C'est une partie complémentaire à commander en même temps que votre ruche, qui vous permet d'installer 5 pots de miel vide à l'envers, dans lesquels les abeilles vont venir construire des alvéoles et les remplir de miel. Il vous suffira de les récupérer plein pour déguster votre propre production ! Bonne dégustation :)",
      },
      {
        title: "Je n’aurai pas beaucoup de temps à leur consacrer, est-il raisonnable d’installer cette ruche ?",
        content:
          "La ruche Apiago est conçue de telle sorte qu’elle est complètement autonome sur les actions basiques nécessaires à la bonne santé des abeilles. Si des traitements contre des maladies sont nécessaires pour garantir la survie de votre ruche, ceux-ci vous seront conseillés automatiquement par l’application associée. Cela ne vous prendra pas plus de 10 minutes.",
      },
      {
        title: "Que dois-je faire si je pars en vacances ?",
        content:
          "Rien ! Elles se débrouillent très bien sans nous ! Elles étaient là avant nous, et on espère bien qu'elles seront là après nous !",
      },
      {
        title: "Un abreuvoir pour les abeilles ? késaco ?",
        content:
          "Elles sont comme nous, inutile de faire des kilomètres quand tout est à disposition, elles iront au plus proche pour trouver un point d'eau. Si vous en avez la possibilité, vous pouvez leur mettre un petit 'abreuvoir' avec des flotteurs type bouchon de lièges reliés entre eux par des cures dents pour qu'elles ne se noient pas ",
      },
      {
        title: "Pourquoi la reine de la colonie ne mourra-t-elle jamais de vieillesse dans la ruche APIAGO ?",
        content:
          "Tout simplement parce que chaque année, les abeilles se reproduisent par essaimage. La reine actuelle s'en va au printemps avec environ la moitié de l'essaim et une nouvelle reine prend le relais. Chaque année, vous démarrez avec une reine toute jeune",
      },
      {
        title: "Qu'est-ce que l'essaimage et est-ce dangereux ?",
        content:
          "C'est leur méthode de reproduction ! Chaque année, au printemps entre mars et juin, les abeilles se reproduisent par essaimage. La reine actuelle s'en va avec environ la moitié de l'essaim et une nouvelle reine prend le relais. Cette dernière effectue un vol pour se faire féconder et prendre la suite de l'ancienne reine. Lors de l'essaimage, les abeilles sont gorgées de miel pour démarrer leur nouvelle vie et ne sont pas du tout agressive. Vous pouvez vous retrouver au milieu d'un nuage d'abeilles sans aucun risque (si ce n'est celui de retrouver sur vos vêtements des petites déjections jaune d'abeilles ^^)",
      },
      {
        title: "La ruche APIAGO essaime-t-elle ?",
        content:
          "Oui ! Et c'est là tout l'enjeu d'une ruche de biodiversité ! La ruche APIAGO peut même essaimer plusieurs fois au printemps ! Si vous êtes un passionné, vous pouvez récupérer jusqu'à 4 essaims et les mettre dans des ruches plus traditionnelles",
      },
      {
        title: "Comment s'installe la ruche APIAGO - quel poids fait-elle ?",
        content:
          "La ruche APIAGO a été étudiée pour s'installer très facilement. Sur un mur, seules 4 fixations sont requises, dans du bois, béton ou brique - nous avons toutes les solutions à dispositions. La ruche s'assemble par blocs qui sont facilement manipulables en haut d'une échelle, que l'on monte au sol et qu'on assemble directement sur le mur par emboitements, très simplement. Le poids à vide est un peu plus de 20Kg. Pleine, la ruche peut peser jusqu'à 40 Kg ",
      },

      {
        title: "A quoi sert l'électronique, est-ce obligatoire ?",
        content:
          "L'électronique sert à vous accompagner dans la compréhension des abeilles et à surveiller l'état de santé de votre colonie. Si vous avez déjà des connaissances et souhaitez gérer vous même votre colonie, pas de problème, la ruche APIAGO a été conçue pour être entièrement mécanique et pouvant fonctionner sans électricité et sans électronique.",
      },
      {
        title: "Et si l'électronique tombe en panne ?",
        content:
          "L'electronique est simplement une aide à la gestion de la colonie. Si elle venait à tomber en panne, cela n'a pas d'impact sur les abeilles. Notre but n'est pas d'être intrusif mais bien de leur mettre à disposition un environnement au plus proche de ce qu'elles peuvent trouver dans la nature. Toutes nos pièces sont remplaçables si l'une d'elle venait à défaillir. N'hésitez pas à nous contacter si besoin",
      },
      {
        title: "Les ondes de la ruche sont-elles nocives pour les abeilles ?",
        content:
          "Non. Nous vivons dans un environnement baigné d'ondes en permanence (excepté les dans les 'zones blanches' qui sont devenues rarissimes en Europe) et les abeilles comme les autres insectes, se portent très bien. La ruche APIAGO, pour ne pas en rajouter inutilement, envoie des données une seule fois par jour, pendant un laps de temps de moins d'une minute. Vous êtes nettement plus exposés aux ondes avec votre téléphone, voiture, ou même chez vous en WiFi que les abeilles de la ruches APIAGO :)",
      },
    ],
  };
  const data3 = {
    title: "Les essaims d'abeilles",
    rows: [
      {
        title: "Quel est le rayon d'action de butinage des abeilles ?",
        content:
          "Les abeilles vont jusqu'à 2-3 km autour de leur ruche pour trouver leur nourriture, mais là encore, elles sont comme nous, plus c'est proche, mieux c'est, car elles dépensent alors moins d'énergie",
      },
      {
        title: "J'habite proche de champs, potentiellements traités avec des pesticides, est-ce un problème ?",
        content:
          "Non. Non pas qu'elles soient insensibles à ceux-ci mais la plantation n'est peut-être pas courtisée par les abeilles comme les betteraves par exemple. De plus les abeilles, si elles ont le choix, varient leur nourriture pour mieux résister aux parasites, maladies, etc. . Leur imposer une seule source de nourriture c'est comme pour nous, si vous ne mangez que des carottes, même si c'est bon pour la santé, vous aurez des carences. c'est pareil pour elles ! ",
      },
      {
        title: "Si mon essaim vient à disparaître, puis-je le remplacer ?",
        content:
          "La ruche Apiago permet, comme les ruches traditionnelles, de retirer les cadres laissés par la colonie disparue et de les remplacer par de nouveaux, avec un nouvel essaim. La désinfection de la ruche est… automatique ;-) Et parce qu’on n’aime pas les designs développés pour de la vente forcée de produits spécifiques, la ruche Apiago permet l’utilisation de cadres standards. Ainsi, vous pouvez acheter un essaim à coût classique (entre 100 et 170 € en moyenne) par vos propres moyens ou via notre site",
      },
      {
        title: "Peut-on être attaqué par un essaim d’abeilles même si on ne touche pas à la ruche ?",
        content:
          "Non, les abeilles n’ont pas le même comportement que celui du frelon.",
      },
      {
        title: "J'ai pourtant déjà été piqué par une abeille sans raison, c'est possible ?",
        content:
          "Non, la plupart du temps beaucoup de personnes confondent abeilles et guêpes. Une abeille quand elle pique, reste accorchée sur vous car elle ne peut pas s'en aller sans mourrir. Elle n'attaque que lorsqu'elle se sent agressée où pour défendre sa colonie. Si vous êtes proche d'une ruche et sur le trajet d'envol, elles peuvent alors s'emmeler dans vos cheveux et peuvent piquer. Un autre cas de figure est lorsqu'elles sont en fin de vie. Elles sont alors souvent au sol et si vous leur marchez dessus pied nus, il y a effectivement un risque de piqure",
      },
    ],
  };

  return (
    <>
      <div class="fondShop"></div>
      <Container>
        <Row className="mx-auto my-auto">
          <Col md="10" className="mx-auto mt-10">
            <h1 className="text-center text-red font-bold font-40px text-uppercase">
              Foires au questions
            </h1>
            <p>
              La ruche Apiago, produit nouveau, produit unique. Nous nous
              efforçons de répondre ici à vos principales interrogations.
              N’hésitez pas à nous contacter si vous ne trouvez pas votre
              réponse ici.
            </p>
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data1} />
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data2} />
          </Col>
        </Row>
        <Row className="mt-10 mb-10">
          <Col>
            <FaqComponent data={data3} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Faq;
